import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import strings from "store/Localization";

function PrivacyPolicy(props: any) {
  const params = useParams();
  // eslint-disable-next-line
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );
  useEffect(() => {
    console.log(params?.lang);
    if (params?.lang) {
      strings.setLanguage(params?.lang);
      setState({});
      return;
    }
    strings.setLanguage("fr");
    setState({});
    // eslint-disable-next-line
  }, [params?.lang]);

  return (
   <div className="body"> 
    
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "10px",
        }}
      >
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="/">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            height={200}
            width={200}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 992.9 653.7"
            xmlSpace="preserve"
            style={{ fill: "#ee6931" }}
          >
            <title>Asset 1</title>
            <g>
              <g id="Layer_1-2">
                <polygon
                  className="st0"
                  points="49.3,338.7 171.1,306 169.6,277.1 17,318 30.4,574.7 61.2,566.5 56,468.7 158.3,441.3 156.9,414.2 
			54.6,441.6 		"
                />
                <polygon
                  className="st0"
                  points="957.5,66 924,75 814.6,248.4 807.4,106.6 776.6,114.8 789.9,371.2 820.7,363 816.6,284.5 
			853.1,227.8 942.2,330.4 976,321.4 871.2,203.9 		"
                />
                <path
                  className="st0"
                  d="M714.1,131.6l10.6,199.6L573.4,168.9l-24,6.4c0,0,7.6,142.8,7.6,146.1c0.3,22.2-5.1,44-15.7,63.5
			c-3.8,7-8.2,13.6-13.2,19.7c-2.9,3.6-6,7-9.3,10.2c-20.9,20.9-48.4,33.9-77.8,36.8c-11.9,1.2-23.9,0.8-35.8-1.3
			c-9.4-1.6-18.6-4.3-27.4-8c-22.6-9.5-42.1-25.2-56.2-45.3l0.5-0.2c8.6-4.8,16.1-11.2,22.3-18.8c12.7-15.6,20.4-34.6,22.2-54.5
			c1-9.9,0.1-20-2.4-29.6c-2.2-8.1-5.6-15.9-10.2-22.9c-4.4-6.8-9.9-12.8-16.4-17.6c-6.4-4.6-13.6-7.9-21.3-9.7
			c-8.2-1.8-16.7-1.5-24.7,0.8L192.4,271l13.4,256.7l30.8-8.3l-4.9-92.2l59.5-16l1.7-0.8c18.4,28.2,45,49.9,76.3,62.2
			c9,3.5,18.3,6.3,27.7,8.1c15.6,3,31.5,3.7,47.3,2c8.6-0.9,17.1-2.5,25.5-4.7c23.1-6.2,44.6-17.6,62.7-33.3
			c7.3-6.2,14-13.1,20.1-20.5c1.5-1.9,3-3.8,4.5-5.8c1.7-2.3,3.3-4.5,4.8-6.9c17-25.5,26.3-55.4,26.7-86c0.1-6.4-5.2-98-5.2-98
			l149.2,158.8l25.7-6.9l-13.3-256.4L714.1,131.6z M299,379.9l-19.5,5.2l-49.2,13.2l-5.5-106.7l66.7-17.9c4.4-1.2,9-1.2,13.4-0.1
			c4.4,1.2,8.5,3.3,12.1,6.1c3.9,3.1,7.3,6.9,9.9,11.1c3,4.6,5.2,9.6,6.6,14.9c2,7.5,2.6,15.4,1.8,23.2c-0.8,7.7-2.7,15.2-5.8,22.3
			c-2.9,6.8-7.1,12.9-12.4,18.2c-2.3,2.3-4.8,4.3-7.6,5.9C306.3,377.4,302.7,378.9,299,379.9"
                />
                <path
                  className="st0"
                  d="M399.6,429l8.6-31.8l40.5-156.8c13.5,33.9,53.3,120.1,64.4,153c3.3-3.3,6.4-6.7,9.3-10.2
			c5-6.2,9.4-12.8,13.2-19.7l-75.9-164.1l-26.4,7.1L372.2,421C381,424.7,390.2,427.4,399.6,429"
                />
              </g>
            </g>
          </svg>
          </a>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p className="main_heading">{strings.privact_policy}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // width: "700px",
            flexDirection: "column",
          }}
        >
          <p className="content_heading">{strings.intoduction}</p>
          <p className="content">{strings.intro_para_1}</p>
          <p className="content">{strings.intro_para_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.defination}</p>
          <p className="content">{strings.defination_desc}</p>
          <p className="content">{strings.def_point_1}</p>
          <p className="content">{strings.def_point_2}</p>

          <p className="content">{strings.def_point_3}</p>
          <p className="content">{strings.def_point_4}</p>
          <p className="content">{strings.def_point_5}</p>
          <p className="content">{strings.def_point_6}</p>
          <p className="content">{strings.def_point_7}</p>
          <p className="content">{strings.def_point_8}</p>
          <p className="content">{strings.def_point_9}</p>
          <p className="content">{strings.def_point_10}</p>
          <p className="content">{strings.def_point_11}</p>
          <p className="content">{strings.def_point_12}</p>
          <p className="content">{strings.def_point_13}</p>
          {/* <p className="content">{strings.def_point_14}</p> */}
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.information_1}</p>
          <p className="content">{strings.info_intro_1}</p>
          <p className="content">{strings.info_value_1}</p>
          <p className="content">{strings.info_value_2}</p>

          <p className="content">{strings.info_value_3}</p>
          <p className="content">{strings.info_value_4}</p>
          <p className="content">{strings.info_value_5}</p>
          <p className="content">{strings.info_value_6}</p>
          <p className="content">{strings.info_value_7}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.information_2}</p>
          <p className="content">{strings.info_intro_2}</p>
          <p className="content">{strings.info_2_value_1}</p>
          <p className="content">{strings.info_2_value_2}</p>

          <p className="content">{strings.info_2_value_3}</p>
          <p className="content">{strings.info_2_value_4}</p>
          <p className="content">{strings.info_2_value_5}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            alignItems: "flex-start",

            flexDirection: "column",
          }}
        >
          <p className="content_sub_heading">{strings.information_3}</p>
          <p className="content">{strings.info_intro_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Use_of_information}</p>
          <p className="content">{strings.use_of_info_intro}</p>
          <p className="content">{strings.use_of_info_desc_1}</p>
          <p className="content">{strings.use_of_info_desc_2}</p>
          <p className="content">{strings.use_of_info_desc_3}</p>
          <p className="content">{strings.use_of_info_desc_4}</p>
          <p className="content">{strings.use_of_info_desc_5}</p>
          <p className="content">{strings.use_of_info_desc_6}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.sharing_of_information_title}
          </p>
          <p className="content">{strings.sharing_of_information_intro}</p>
          <p className="content">{strings.sharing_of_information_desc_1}</p>
          <p className="content">{strings.sharing_of_information_desc_2}</p>
          <p className="content">{strings.sharing_of_information_desc_3}</p>
          <p className="content">{strings.sharing_of_information_desc_4}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.security_title}</p>
          <p className="content">{strings.security_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.your_rights}</p>
          <p className="content">{strings.your_rights_intro}</p>
          <p className="content">{strings.your_rights_desc_1}</p>
          <p className="content">{strings.your_rights_desc_2}</p>
          <p className="content">{strings.your_rights_desc_3}</p>
          <p className="content">{strings.your_rights_desc_4}</p>
          <p className="content">{strings.your_rights_desc_5}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.data_retentions_title}</p>
          <p className="content">{strings.data_retentions_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.international_data_transfer_title}
          </p>
          <p className="content">{strings.international_data_transfer_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.changes_to_privacy_policy_title}
          </p>
          <p className="content">{strings.changes_to_privacy_policy_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            alignItems: "flex-start",

            flexDirection: "column",
          }}
        >
          <p className="content_heading">{strings.contact_us_title}</p>
          <p className="content">{strings.contact_us_intro_1}</p>
          <p className="content">{strings.contact_us_desc_1}</p>
          <p className="content">{strings.contact_us_desc_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.idditional_provisions_title}
          </p>
          <p className="content_sub_heading">
            {strings.additional_provisions_title_1}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_1}
          </p>

          <p className="content">{strings.additional_provisions_desc_1}</p>
          <p className="content">{strings.additional_provisions_desc_2}</p>
          <p className="content">{strings.additional_provisions_desc_3}</p>
          <p className="content">{strings.additional_provisions_desc_4}</p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_2}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_2}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_3}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_3}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_4}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_4}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_5}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_5}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_6}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_6}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_7}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_7}
          </p>

          <p className="content">
            {strings.additional_provisions_title_desc_7_1}
          </p>
          <p className="content">
            {strings.additional_provisions_title_desc_7_2}
          </p>
          <p className="content">
            {strings.additional_provisions_title_desc_7_3}
          </p>
          <p className="content">
            {strings.additional_provisions_title_desc_7_4}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_8}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_8}
          </p>

          <p className="content">
            {strings.additional_provisions_title_8_desc}
          </p>

          <p className="content_sub_heading">
            {strings.additional_provisions_title_9}
          </p>
          <p className="content">
            {strings.additional_provisions_title_intro_9}
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.cookies_title}</p>
          <p className="content">{strings.cookies_intro_1}</p>

          <p className="content">{strings.cookies_intro_2}</p>
          <p className="content">{strings.cookies_intro_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.mooderation_of_messages_title}
          </p>
          <p className="content">{strings.mooderation_of_messages_intro}</p>

          <p className="content">{strings.mooderation_of_messages_desc_1}</p>
          <p className="content">{strings.mooderation_of_messages_desc_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.services_provides_by_third_party_title}
          </p>
          <p className="content">
            {strings.services_provides_by_third_party_intro_1}
          </p>

          <p className="content">
            {strings.services_provides_by_third_party_intro_2}
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.retention_period_title}</p>
          <p className="content_sub_heading">
            {strings.retention_period_intro_1}
          </p>

          <p className="content">{strings.retention_period_intro_1_intro}</p>

          <p className="content_sub_heading">
            {strings.retention_period_intro_2}
          </p>

          <p className="content">{strings.retention_period_intro_2_intro}</p>

          <p className="content_sub_heading">
            {strings.retention_period_intro_3}
          </p>

          <p className="content">{strings.retention_period_intro_3_intro}</p>

          <p className="content_sub_heading">
            {strings.retention_period_intro_4}
          </p>

          <p className="content">{strings.retention_period_intro_4_intro}</p>

          <p className="content_sub_heading">
            {strings.retention_period_intro_5}
          </p>

          <p className="content">{strings.retention_period_intro_5_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.user_rights_title}</p>
          <p className="content">{strings.user_rights_intro}</p>

          <p className="content">{strings.user_rights_desc_1}</p>

          <p className="content">{strings.user_rights_desc_2}</p>

          <p className="content">{strings.user_rights_desc_3}</p>

          <p className="content">{strings.user_rights_desc_4}</p>

          <p className="content">{strings.user_rights_desc_5}</p>

          <p className="content">{strings.user_rights_desc_6}</p>

          <p className="content">{strings.user_rights_intro_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.changes_to_this_policy_title}
          </p>
          <p className="content">{strings.changes_to_this_policy_intro}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.contact_information_title}</p>
          <p className="content">{strings.contact_information_intro}</p>
        </Grid>
      </Grid>
      </div>
    
  );
}

export default PrivacyPolicy;
