/*
  Created By Muhammad Hassaan
  Date 10 April 2023
*/

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme: any) => ({
  testWeb: {
    display: 'flex',  
    [theme.breakpoints?.down('md')]: {
      display: 'none',
    },
  },
  testMobile: {
    display: 'none',  
    [theme.breakpoints?.down('sm')]: {
      display: 'flex',
    },
  },
  displayFlex:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default useStyles;
