// import { get, post } from "./HttpProvider";

// const getFromApi = (url: any, permission_name: any) =>
//   get(url, permission_name, null);

// const postFromApi = (url: any, data: any, permission_name: any) =>
//   post(url, data, permission_name, null);

// export const apiServices = {
//   getFromApi,
//   postFromApi,
// };
// export default apiServices;


import { get, post, patch, patchfortoken } from "./HttpProvider";

const getFromApi = (url: any, permission_name: any) => get(url, permission_name, null);

const postFromApi = (url: any, data: any, permission_name: any) => post(url, data, permission_name, null);

const patchFromApi = (url: any, data: any, permission_name: any) => patch(url, data, permission_name, null);

const uploadFile = (url: any, data: any, permission_name: any) => post(url, data, permission_name, null);

const updateToken = (url: any, data: any) => patchfortoken(url, data,"","");

export const apiServices = {
  getFromApi,
  postFromApi,
  uploadFile,
  patchFromApi,
  updateToken,
};
export default apiServices;