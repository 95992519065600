import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

// routing
import AppRoute from "routes";

// defaultTheme

// project imports

function App() {
  return (
    <>
      <ToastContainer limit={1} />
      <AppRoute />
    </>
  );
}

export default App;
