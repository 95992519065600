import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
 

export function AppRoute() {
  const classes = useStyles() as any;
  let element = MainAppRoutes({ classes });
  return useRoutes([element]);
}
export default AppRoute;
