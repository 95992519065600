import { Grid } from "@mui/material";
import React from "react";
import { spacing } from "utils/constants";
import mobileViewBanner from "../../assets/images/FrankIcons/homepage-mobile.png";
import { FrankIcon } from "assets/images/FrankIcons";
import CustomButton from "ui-components/CustomButton";
import { ArrowCircleRight, ArrowForward, Margin } from "@mui/icons-material";
import iconOne from "../../assets/images/FrankIcons/livraison.png";
import iconTwo from "../../assets/images/FrankIcons/economique.png";
import imageParticular from "../../assets/images/FrankIcons/particlular.jpg";
import imageProfessional from "../../assets/images/FrankIcons/professional.jpg";
import trainImage from "../../assets/images/FrankIcons/trainImage.jpg";
import iconThree from "../../assets/images/FrankIcons/assuree.png";
import boxImage from "../../assets/images/FrankIcons/boxImage.jpg";
import bottomActionMobile from "../../assets/images/FrankIcons/download-app-banner.png";
import googlePlayFrench from "../../assets/images/google-play-store-french.png";
import appStoreFrench from "../../assets/images/FrankIcons/app-store.png";
import arrow from "../../assets/images/FrankIcons/arrow-dots.png";
import imageBannerTwo from "../../assets/images/FrankIcons/mobile-app-steps.png";

import { useNavigate } from "react-router-dom";
export default function MobileView(props: any) {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/about-us");
  };

  const { classes } = props;
  return (
    <div>
     <Grid container spacing={spacing?.spacing2}>
  <Grid xs={12} item style={{ position: "relative", zIndex: "1" }}>
    <img
      src={mobileViewBanner}
      style={{ objectFit: "contain", width: "100%", height: "100%" }}
      alt=""
    />
    <div
      style={{
        display: "flex",
        position: "absolute",
        height: "100px",
        top: "32px",
        left: "32px",
        width: "calc(100% - 64px)", // Ensure it takes the full width minus the left margin
        zIndex: 2, // Ensure it's above the image
      }}
    >
      <div
        style={{
          flex: 1,
          cursor: "pointer", // Indicate it's clickable
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <FrankIcon height={80} fill={"#fff"} />
      </div>
      <div style={{ placeContent:'right',textAlign:'right'}}>
        <button
          disabled
          className="btn btn-primary"
          style={{
            border: "2px solid #fff",
            borderRadius: "30px",
            padding: "12px",
            width: "100%",
          }}
        >
          Mon compte
        </button>
      </div>
    </div>

    <div
      style={{
        position: "absolute",
        bottom: "52%",
        left: "12%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <p
          style={{
            fontFamily: "Raleway-Italic",
            fontWeight: "900",
            fontSize: "28px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          LE CHOIX
          <br />
          D'UNE LIVRAISON
          <br />
          <span style={{ color: "#f9d9b6", fontFamily: "Raleway-Italic" }}>
            QUI VOUS RESSEMBLE!
          </span>
        </p>
        <p
          style={{
            fontFamily: "Raleway-Italic",
            fontSize: "14px",
            color: "#fff",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          Envoyez des colis à prix réduits, de maniére
          <br />
          éco-responsble et découvrez toute une
          <br />
          communauté.
        </p>
        <p style={{ marginTop: "15px", cursor: "pointer" }}>
          <CustomButton
            value={"DÉCOUVREZ QUI NOUS SOMMES"}
            endIcon={<ArrowCircleRight style={{ color: "#f9d9b6", fontSize: "22px" }} />}
            style={{
              background: "#3026f1",
              color: "#fff",
              fontFamily: "Raleway-Variable",
              fontWeight: "700px",
              borderRadius: "8px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={handleNavigation}
          />
        </p>
      </div>
    </div>
  </Grid>
</Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid xs={12} item style={{ marginTop: "50px" }}>
          <div>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontWeight: "900",
                fontSize: "45px",
                color: "#3026f1",
              }}
            >
              POURQUOI
              <br />
              CHOISIR
              <br />
              <span style={{ color: "#ee6931", fontFamily: "Raleway-Italic" }}>
                FRANK
              </span>{" "}
              ?
            </p>
          </div>
        </Grid>
        <Grid xs={12} item style={{ marginTop: "70px" }}>
          <div style={{ width: "200px", position: "relative" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconOne}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={12} item style={{ marginTop: "70px" }}>
          <div style={{ width: "200px", position: "relative" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconTwo}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={12} item style={{ marginTop: "70px" }}>
          <div style={{ width: "200px", position: "relative" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconThree}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={spacing?.spacing2}>
        <Grid xs={12} item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "25px",
                  fontWeight: "900",
                  color: "#ee6931",
                  textAlign: "center",
                }}
              >
                LA MARKETPLACE FRANK
                <span
                  style={{ fontFamily: "Raleway-Italic", color: "#3026f1" }}
                >
                  , POUR LES PARTICULIERS COMME POUR LES PROFESSIONNELS{" "}
                </span>
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <div>
        <Grid container spacing={spacing?.spacing2} style={{ padding: "35px" }}>
          <Grid xs={12} item>
            <div>
              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageParticular}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "900",
                      }}
                    >
                      PARTICULIERS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Avec Frank, vous pourrez bientôt mettre en vente vos fonds
                    de tiroirs et opter pour une livraison verte, à prix
                    réduits.
                  </p>
                  <p>
                    Notre envie ? Vous permettre de vider vos placards tout en
                    remplissant vos poches !
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={spacing?.spacing2} style={{ padding: "35px" }}>
          <Grid xs={12} item>
            <div>
              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageProfessional}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontWeight: "900",
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      PROFESSIONNELS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Et si vous êtes un professionnel, notre service vous
                    permettra de mettre en ligne vos articles en un rien de
                    temps !
                  </p>
                  <p>
                    Le plus ? Avec Frank des ventes assurées et une livraison
                    plus responsable.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ zIndex: "-11111" }}>
          <Grid
            xs={12}
            item
            style={{ width: "100%", margin: "0px", padding: "0px" }}
          >
            <img
              src={imageBannerTwo}
              style={{
                width: "100%",
                // position: "absolute",
                zIndex: "-1",
              }}
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={spacing?.spacing2} style={{ marginTop: "20px" }}>
        <Grid xs={12} item>
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontSize: "25px",
              fontWeight: "900",
              textAlign: "center",
              color: "#3026f1",
            }}
          >
            FRANK, C'EST AUSSI L'ALTERNATIVE
            <br />
            DE LIVRAISON POUR LES SITES
            <br /> E-COMMERCE
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Frank offre un service de livraison éco-responsable, économique et
            flexible pour la vente de vos produits comme pour vos livraisons.
          </p>
          <br />
          <p
            style={{
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Frank s’occupe de la collecte, la préparation de commande et même la
            livraison au client final.
          </p>
        </Grid>

        <Grid xs={12} item style={{}}>
          <p
            style={{
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Vous pourrez aussi intégrer la solution Frank dans vos systèmes de
            gestion existants via des{" "}
            <span style={{ fontWeight: "600" }}> API</span>. Pour en savoir plus
            contactez nous sur
            <br />
            <a
              href="mailto:Hello@frankme.com"
              style={{
                textDecoration: "none",
                color: "#000",
                fontWeight: "600",
              }}
            >
              hello@frankme.com
            </a>
          </p>
        </Grid>
      </Grid>

      <Grid
        style={{
          background: "#f9c9b3",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
        container
        spacing={spacing?.spacing2}
      >
        {/* <Grid xs={12} item>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Raleway-Italic",
              fontSize: "28px",
              fontWeight: "900",
              color: "#3026f1",
              // padding: "50px",
            }}
          >
            NOS ACTUS
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div className="image-container-one-for-bottom">
            <div className="image-container-bottom">
              <img
                src={trainImage}
                alt="Particulier"
                className="background-image-bottom"
              />
              <div
                className="text-container-bottom"
                style={{ display: "flex", padding: "10px 30px 0px 30px" }}
              >
                <div style={{ flex: 1, color: "black" }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "800",
                      fontSize: "19px",
                    }}
                  >
                    Du Nouveau chez Frank
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-italic",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Il y a 10 heures
                  </p>
                </div>
                <div>
                  <ArrowForward
                    style={{
                      color: "#3026f1",
                      background: "#f9c9b3",
                      borderRadius: "100%",
                      padding: "3px",
                      fontSize: "26px",
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <br />
              <div style={{ padding: "0px 30px 30px 30px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </p>
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div className="image-container-one-for-bottom">
            <div className="image-container-bottom">
              <img
                src={boxImage}
                alt="Particulier"
                className="background-image-bottom"
              />
              <div
                className="text-container-bottom"
                style={{ display: "flex", padding: "10px 30px 0px 30px" }}
              >
                <div style={{ flex: 1, color: "black" }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "800",
                      fontSize: "19px",
                    }}
                  >
                    Du Nouveau chez Frank
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-italic",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Il y a 2 jours
                  </p>
                </div>
                <div>
                  <ArrowForward
                    style={{
                      color: "#3026f1",
                      background: "#f9c9b3",
                      borderRadius: "100%",
                      padding: "3px",
                      fontSize: "26px",
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <br />
              <div style={{ padding: "0px 30px 30px 30px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </p>
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div className="image-container-one-for-bottom">
            <div className="image-container-bottom">
              <img
                src={imageParticular}
                alt="Particulier"
                className="background-image-bottom"
              />
              <div
                className="text-container-bottom"
                style={{ display: "flex", padding: "10px 30px 0px 30px" }}
              >
                <div style={{ flex: 1, color: "black" }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "800",
                      fontSize: "19px",
                    }}
                  >
                    Du Nouveau chez Frank
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-italic",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Il y a 3 jours
                  </p>
                </div>
                <div>
                  <ArrowForward
                    style={{
                      color: "#3026f1",
                      background: "#f9c9b3",
                      borderRadius: "100%",
                      padding: "3px",
                      fontSize: "26px",
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <br />
              <div style={{ padding: "0px 30px 30px 30px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                  adipiscing odio et bibendum duis. Auctor scelerisque tellus
                  tortor euismod...
                </p>
              </div>
            </div>
          </div>
        </Grid> */}

        <Grid xs={12} item>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "10%" }}>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "24px",
                  fontWeight: "800",
                  textAlign: "center",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                Téléchargez l'application mobile Frank
              </p>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Rejoignez gratuitement la
                <br /> communauté de livraison verte
              </p>
            </div>
            <img
              src={bottomActionMobile}
              style={{ width: "100%", height: "100%", zIndex: -1 }}
              alt=""
            />
            <a
              style={{
                position: "absolute",
                left: "26%",
                top: "27%",
              }}
              href="https://apps.apple.com/app/frank/id6502341979"
              target="_blank"
            >
              <img
                src={appStoreFrench}
                style={{
                  height: "59px",
                }}
                alt=""
              />
            </a>
          
            <a
              style={{
                position: "absolute",
                left: "26%",
                top: "37%",
              }}
              href="https://play.google.com/store/apps/details?id=com.frankc2c"
              target="_blank"
            >
              <img
                src={googlePlayFrench}
                style={{
                  height: "55px",
                }}
                alt=""
              />
            </a>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontSize: "22px",
              fontWeight: "700",
            }}
          >
           <a href="/about-us" style={{fontFamily:'Raleway-Italic',color:'#000',textDecoration:'none'}}> Qui sommes-nous ?</a>
            <br />
            Nous Contacter :
          </p>
          <p
            style={{
              color: "#3026f1",
              fontFamily: "Raleway-Italic",
              fontWeight: "800",
            }}
          >
            <a
              href="mailto:Hello@frankme.com"
              style={{
                textDecoration: "none",
                fontFamily: "Raleway-Italic",
              }}
            >
              → hello@frankme.com
            </a>
          </p>{" "}
        </Grid>

    
          <div
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "14px",
              paddingBottom:'20px'
            }}
          >
            <a
              href="/terms-and-condition"
              style={{ textDecoration: "none", fontFamily: "Raleway-Italic",color:'#ee6931',fontWeight:'600' }}
            >
              {" "}
              Termes et conditions
            </a>{" "}
            &nbsp; | &nbsp;
            <a
              href="/privacy-policy"
              style={{ textDecoration: "none", fontFamily: "Raleway-Italic",color:'#ee6931',fontWeight:'600' }}
            >
              {" "}
              Politique de confidentialité
            </a>
          </div>
         
      </Grid>
    </div>
  );
}
