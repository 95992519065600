interface Data {
  [key: string]: any;
}
 
export const saveDataToLocalStorage = (key: string, data: Data): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string): Data | null => {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    return JSON.parse(storedData);
  }
  return null;
};

export const clearDataFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const clearAllDataFromLocalStorage = (): void => {
  localStorage.clear();
};

export const getCart = () => {
  const cart = localStorage.getItem('cart');
  return cart ? JSON.parse(cart) : [];
};

export const addProductToCart = (product:any) => {
  const cart = getCart();
  const isProductInCart = cart.some((item:any) => item.id === product.id);

  if (!isProductInCart) {
    cart.push(product);
    localStorage.setItem('cart', JSON.stringify(cart));
  }
};

export const removeProductFromCart = (productId:any) => {
  let cart = getCart();
  cart = cart.filter((item:any) => item.id !== productId);
  localStorage.setItem('cart', JSON.stringify(cart));
};