import Check from "./FormControl/Check";

export const CheckIcon = Check;
interface IconProps {
  height?: number | undefined;
  width?: number | undefined;
  stroke_width?: number | undefined;
  color?: number | undefined;
  fill?: any | undefined;
}

export const FrankIcon = ({ height, width, stroke_width, fill }: IconProps) => (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 992.89 653.71"
      fill={fill}
    >
      <defs>
        {/* <style
          dangerouslySetInnerHTML={{
            __html: `
    .cls-1{fill:${fill};}
    .cls-2{font-size:126.28px;fill:#F9C9B3;font-family:Raleway-Italic, Raleway;font-style:italic;}
    .cls-3{letter-spacing:-0.03em;}.cls-4{letter-spacing:0em;}
    .cls-5{letter-spacing:-0.02em;}.cls-6{letter-spacing:-0.03em;}
    .cls-7{letter-spacing:-0.01em;}.cls-8{letter-spacing:-0.02em;}
    `,
          }}
        /> */}
      </defs>
  
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            // className="cls-1"
            points="32.31 272.68 154.1 240.05 152.58 211.12 0 252 13.41 508.74 44.21 500.49 39.02 402.71 141.3 375.3 139.9 348.17 37.61 375.58 32.31 272.68"
          />
          <polygon
            // className="cls-1"
            points="940.48 0 906.96 8.98 797.59 182.39 790.38 40.58 759.6 48.83 772.93 305.23 803.73 296.98 799.57 218.52 836.07 161.81 925.17 264.44 959.04 255.36 854.23 137.88 940.48 0"
          />
          <path
            // className="cls-1"
            d="M697.08,65.58l10.58,199.57L556.39,102.92l-23.95,6.41s7.6,142.85,7.64,146.09a128.1,128.1,0,0,1-15.74,63.46,131,131,0,0,1-13.22,19.74,135.43,135.43,0,0,1-9.29,10.24,128.31,128.31,0,0,1-77.78,36.8,130.13,130.13,0,0,1-35.75-1.26,127.74,127.74,0,0,1-27.37-8,130.06,130.06,0,0,1-56.2-45.33l.48-.23A75.89,75.89,0,0,0,327.49,312a100.51,100.51,0,0,0,22.24-54.55,83.53,83.53,0,0,0-2.42-29.59,80.29,80.29,0,0,0-10.19-22.95,66,66,0,0,0-16.44-17.59,58.1,58.1,0,0,0-21.31-9.69,50.31,50.31,0,0,0-24.72.76L175.43,205l13.43,256.74,30.79-8.25-4.89-92.19,59.51-16,1.66-.77a161.56,161.56,0,0,0,76.34,62.22A158.36,158.36,0,0,0,380,414.88a161.43,161.43,0,0,0,47.25,2.05,164.19,164.19,0,0,0,25.46-4.68A158.88,158.88,0,0,0,515.42,379a162,162,0,0,0,20.09-20.54c1.53-1.9,3-3.84,4.47-5.78,1.66-2.26,3.28-4.54,4.82-6.86a159,159,0,0,0,26.68-86c.1-6.39-5.17-98-5.17-98L715.55,320.6l25.66-6.87L727.87,57.33ZM282,313.94l-19.52,5.23-49.24,13.2-5.49-106.7,66.72-17.88a25.42,25.42,0,0,1,13.35-.09,33.37,33.37,0,0,1,12.09,6.1,45.46,45.46,0,0,1,9.94,11.1,52.06,52.06,0,0,1,6.57,14.92,63.88,63.88,0,0,1,1.8,23.17,74,74,0,0,1-5.77,22.26,58.41,58.41,0,0,1-12.36,18.16,42.17,42.17,0,0,1-7.57,5.94A39.22,39.22,0,0,1,282,313.94"
          />
          <path
            // className="cls-1"
            d="M382.57,363l8.62-31.76,40.5-156.79c13.54,33.87,53.31,120.09,64.41,153a133.34,133.34,0,0,0,9.29-10.24,131,131,0,0,0,13.22-19.74L442.69,133.38l-26.35,7.06L355.2,355a127.15,127.15,0,0,0,27.37,8"
          />
        </g>
      </g>
    </svg>
  );

export const AppStoreFR = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
    <svg
      id="livetype"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 126.50751 40"
    >
      <title>Download_on_the_App_Store_Badge_FR_RGB_blk_100517</title>
      <g>
        <g>
          <path
            d="M116.97821,0H9.53468c-.3667,0-.729,0-1.09473.002-.30615.002-.60986.00781-.91895.0127A13.21476,13.21476,0,0,0,5.5171.19141a6.66509,6.66509,0,0,0-1.90088.627A6.43779,6.43779,0,0,0,1.99757,1.99707,6.25844,6.25844,0,0,0,.81935,3.61816a6.60119,6.60119,0,0,0-.625,1.90332,12.993,12.993,0,0,0-.1792,2.002C.00587,7.83008.00489,8.1377,0,8.44434V31.5586c.00489.3105.00587.6113.01515.9219a12.99232,12.99232,0,0,0,.1792,2.0019,6.58756,6.58756,0,0,0,.625,1.9043A6.20778,6.20778,0,0,0,1.99757,38.001a6.27445,6.27445,0,0,0,1.61865,1.1787,6.70082,6.70082,0,0,0,1.90088.6308,13.45514,13.45514,0,0,0,2.0039.1768c.30909.0068.6128.0107.91895.0107C8.80567,40,9.168,40,9.53468,40H116.97821c.3594,0,.7246,0,1.084-.002.3047,0,.6172-.0039.9219-.0107a13.279,13.279,0,0,0,2-.1768,6.80432,6.80432,0,0,0,1.9082-.6308,6.27742,6.27742,0,0,0,1.6172-1.1787,6.39482,6.39482,0,0,0,1.1816-1.6143,6.60413,6.60413,0,0,0,.6191-1.9043,13.50641,13.50641,0,0,0,.1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938V9.53613c0-.36621,0-.72949-.0078-1.09179,0-.30664,0-.61426-.0039-.9209a13.50709,13.50709,0,0,0-.1856-2.002,6.6177,6.6177,0,0,0-.6191-1.90332,6.46619,6.46619,0,0,0-2.7988-2.7998,6.76753,6.76753,0,0,0-1.9082-.627,13.04394,13.04394,0,0,0-2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002Z"
            style={{ fill: "#a6a6a6" }}
          />
          <path d="M8.44482,39.125c-.30467,0-.602-.0039-.90428-.0107a12.68714,12.68714,0,0,1-1.86914-.1631,5.88381,5.88381,0,0,1-1.65674-.5479,5.40573,5.40573,0,0,1-1.397-1.0166,5.32082,5.32082,0,0,1-1.02051-1.3965,5.72186,5.72186,0,0,1-.543-1.6572,12.41351,12.41351,0,0,1-.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131V8.44434S.88185,7.75293.8877,7.5498a12.37138,12.37138,0,0,1,.16552-1.87207,5.75577,5.75577,0,0,1,.54347-1.6621A5.37365,5.37365,0,0,1,2.61182,2.61768,5.56562,5.56562,0,0,1,4.01417,1.59521a5.82309,5.82309,0,0,1,1.65332-.54394A12.58579,12.58579,0,0,1,7.543.88721L8.44532.875h109.612l.9131.0127a12.38493,12.38493,0,0,1,1.8584.16259,5.93833,5.93833,0,0,1,1.6709.54785,5.59375,5.59375,0,0,1,2.415,2.41993,5.76267,5.76267,0,0,1,.5352,1.64892,12.995,12.995,0,0,1,.1738,1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079,1.09179V30.4648c0,.3633,0,.7178-.0079,1.0752,0,.3252,0,.6231-.0039.9297a12.73126,12.73126,0,0,1-.1709,1.8535,5.739,5.739,0,0,1-.54,1.67,5.48029,5.48029,0,0,1-1.0156,1.3857,5.4129,5.4129,0,0,1-1.3994,1.0225,5.86168,5.86168,0,0,1-1.668.5498,12.54218,12.54218,0,0,1-1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002Z" />
        </g>
        <g>
          <g id="_Group_" data-name="&lt;Group&gt;">
            <g id="_Group_2" data-name="&lt;Group&gt;">
              <g id="_Group_3" data-name="&lt;Group&gt;">
                <path
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  d="M24.7718,20.30068a4.94881,4.94881,0,0,1,2.35656-4.15206,5.06566,5.06566,0,0,0-3.99116-2.15768c-1.67924-.17626-3.30719,1.00483-4.1629,1.00483-.87227,0-2.18977-.98733-3.6085-.95814a5.31529,5.31529,0,0,0-4.47292,2.72787c-1.934,3.34842-.49141,8.26947,1.3612,10.97608.9269,1.32535,2.01018,2.8058,3.42763,2.7533,1.38706-.05753,1.9051-.88448,3.5794-.88448,1.65876,0,2.14479.88448,3.591.8511,1.48838-.02416,2.42613-1.33124,3.32051-2.66914A10.962,10.962,0,0,0,27.691,24.69985,4.78205,4.78205,0,0,1,24.7718,20.30068Z"
                  style={{ fill: "#fff" }}
                />
                <path
                  id="_Path_2"
                  data-name="&lt;Path&gt;"
                  d="M22.04017,12.21089a4.87248,4.87248,0,0,0,1.11452-3.49062,4.95746,4.95746,0,0,0-3.20758,1.65961,4.63634,4.63634,0,0,0-1.14371,3.36139A4.09905,4.09905,0,0,0,22.04017,12.21089Z"
                  style={{ fill: "#fff" }}
                />
              </g>
            </g>
          </g>
          <g id="_Group_4" data-name="&lt;Group&gt;">
            <g>
              <path
                d="M35.65528,14.70166V9.57813h-1.877V8.73486h4.67676v.84326H36.582v5.12354Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M42.76466,13.48584a1.828,1.828,0,0,1-1.95117,1.30273,2.04531,2.04531,0,0,1-2.08008-2.32422,2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27v.31006H39.63868v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117H41.9131a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,39.63868,12.03467ZM40.2754,9.4458l1.03809-1.42236h1.042L41.19337,9.4458Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M44.05274,8.44092h.88867v6.26074h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M50.208,13.48584a1.828,1.828,0,0,1-1.95117,1.30273,2.04531,2.04531,0,0,1-2.08008-2.32422,2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27v.31006H47.082v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117h2.27441a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,47.082,12.03467Zm.63672-2.58887,1.03809-1.42236h1.042L48.63673,9.4458Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M54.40333,11.67041a1.00546,1.00546,0,0,0-1.06348-.76465c-.74414,0-1.19922.57031-1.19922,1.52979,0,.97607.459,1.55908,1.19922,1.55908a.97873.97873,0,0,0,1.06348-.74023h.86426a1.762,1.762,0,0,1-1.92285,1.53418,2.06791,2.06791,0,0,1-2.11328-2.353,2.05305,2.05305,0,0,1,2.1084-2.32373,1.77731,1.77731,0,0,1,1.92773,1.55859Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M56.44728,8.44092h.88086v2.48145h.07031a1.3856,1.3856,0,0,1,1.373-.80664,1.48339,1.48339,0,0,1,1.55078,1.67871v2.90723h-.88965v-2.688c0-.71924-.335-1.0835-.96289-1.0835a1.05194,1.05194,0,0,0-1.13379,1.1416v2.62988h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M61.43946,13.42822c0-.81055.60352-1.27783,1.6748-1.34424l1.21973-.07031V11.625c0-.47559-.31445-.74414-.92188-.74414-.49609,0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953,1.83984-1.26953,1.12891,0,1.76514.562,1.76514,1.51318v3.07666h-.855v-.63281H64.293a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,61.43946,13.42822Zm2.89453-.38477V12.667l-1.09961.07031c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,64.334,13.04346Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M66.60987,10.19873h.85547v.69043h.06641a1.22092,1.22092,0,0,1,1.21582-.76514,1.86836,1.86836,0,0,1,.39648.03711v.877a2.43442,2.43442,0,0,0-.49609-.05371A1.05507,1.05507,0,0,0,67.49855,12.043v2.65869h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M69.96144,15.15234h.90918c.0752.32666.45117.5376,1.05078.5376.74023,0,1.17871-.35156,1.17871-.94678v-.86426H73.0337a1.51433,1.51433,0,0,1-1.38965.75635c-1.14941,0-1.86035-.88867-1.86035-2.23682,0-1.373.71875-2.27441,1.86914-2.27441a1.56045,1.56045,0,0,1,1.41406.79395h.07031v-.71924h.85156v4.54c0,1.02979-.80664,1.68311-2.08008,1.68311C70.7837,16.42188,70.05616,15.91748,69.96144,15.15234Zm3.15527-2.7583c0-.897-.46387-1.47168-1.2207-1.47168-.76465,0-1.19434.57471-1.19434,1.47168,0,.89746.42969,1.47217,1.19434,1.47217C72.65773,13.86621,73.11671,13.2959,73.11671,12.394Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M79.21241,13.48584a1.828,1.828,0,0,1-1.95117,1.30273,2.04531,2.04531,0,0,1-2.08008-2.32422,2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27v.31006H76.08644v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117h2.27441a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,76.08644,12.03467Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M80.45948,10.19873H81.315v.69043h.06641a1.22092,1.22092,0,0,1,1.21582-.76514,1.86836,1.86836,0,0,1,.39648.03711v.877a2.43442,2.43442,0,0,0-.49609-.05371A1.05507,1.05507,0,0,0,81.34815,12.043v2.65869h-.88867Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M86.19581,12.44824c0-1.42285.73145-2.32422,1.86914-2.32422a1.484,1.484,0,0,1,1.38086.79h.06641V8.44092h.88867v6.26074h-.85156v-.71143H89.479a1.56284,1.56284,0,0,1-1.41406.78564C86.91944,14.77588,86.19581,13.87451,86.19581,12.44824Zm.918,0c0,.95508.4502,1.52979,1.20313,1.52979.749,0,1.21191-.583,1.21191-1.52588,0-.93848-.46777-1.52979-1.21191-1.52979C87.56886,10.92236,87.11378,11.501,87.11378,12.44824Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M91.60206,13.42822c0-.81055.60352-1.27783,1.6748-1.34424l1.21973-.07031V11.625c0-.47559-.31445-.74414-.92187-.74414-.49609,0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953,1.83984-1.26953,1.12891,0,1.76563.562,1.76563,1.51318v3.07666h-.85547v-.63281h-.07031a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,91.60206,13.42822Zm2.89453-.38477V12.667L93.397,12.7373c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,94.49659,13.04346Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M96.773,10.19873h.85547v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915h-.88867V12.00977c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428H96.773Z"
                style={{ fill: "#fff" }}
              />
              <path
                d="M103.61769,10.11182c1.0127,0,1.6748.47119,1.76172,1.26514h-.85254c-.082-.33057-.40527-.5415-.90918-.5415-.49609,0-.873.23535-.873.58691,0,.269.22754.43848.71582.55029l.748.17334c.85645.19873,1.25781.56689,1.25781,1.22852,0,.84766-.79,1.41406-1.86523,1.41406-1.07129,0-1.76953-.48389-1.84863-1.28174h.88965a.91365.91365,0,0,0,.97949.562c.55371,0,.94727-.248.94727-.60791,0-.26855-.21094-.44238-.66211-.5498l-.78516-.18213c-.85645-.20264-1.25293-.58691-1.25293-1.25684C101.86866,10.67383,102.60011,10.11182,103.61769,10.11182Z"
                style={{ fill: "#fff" }}
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="M35.19825,18.06689h1.85938V30.48535H35.19825Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M39.29786,22.61084l1.01563-4.54395h1.80664l-1.23047,4.54395Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M49.14649,27.12891H44.4131l-1.13672,3.35645H41.27149l4.4834-12.41846h2.083l4.4834,12.41846H50.28224Zm-4.24316-1.54883h3.752l-1.84961-5.44775h-.05176Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M62.00294,25.959c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.43115h1.79883V22.937h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C60.48829,21.33643,62.00294,23.15283,62.00294,25.959Zm-1.91016,0c0-1.8335-.94727-3.03857-2.39258-3.03857-1.41992,0-2.375,1.23047-2.375,3.03857,0,1.82422.95508,3.0459,2.375,3.0459C59.14552,29.00488,60.09278,27.80859,60.09278,25.959Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M71.9673,25.959c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438H63.43946V21.43115H65.2378V22.937H65.272a3.21162,3.21162,0,0,1,2.88281-1.60059C70.45265,21.33643,71.9673,23.15283,71.9673,25.959Zm-1.91016,0c0-1.8335-.94727-3.03857-2.39258-3.03857-1.41992,0-2.375,1.23047-2.375,3.03857,0,1.82422.95508,3.0459,2.375,3.0459C69.10987,29.00488,70.05714,27.80859,70.05714,25.959Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M78.55323,27.02539c.1377,1.23145,1.334,2.04,2.96875,2.04,1.56641,0,2.69336-.80859,2.69336-1.91895,0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.38818C78.03663,24.271,76.978,23.20459,76.978,21.47412c0-2.14258,1.86719-3.61426,4.51855-3.61426,2.624,0,4.42285,1.47168,4.4834,3.61426H84.104c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148,1.8584c0,.87793.6543,1.39453,2.25488,1.79l1.36816.33594c2.54785.60254,3.60645,1.62646,3.60645,3.44287,0,2.32324-1.85059,3.77832-4.79395,3.77832-2.75391,0-4.61328-1.4209-4.7334-3.667Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M90.19,19.28857v2.14258h1.72168v1.47168H90.19v4.9917c0,.77539.34473,1.13672,1.10156,1.13672a5.80752,5.80752,0,0,0,.61133-.043v1.46289a5.10351,5.10351,0,0,1-1.03223.08594c-1.833,0-2.54785-.68848-2.54785-2.44434V22.90283H87.00636V21.43115h1.31641V19.28857Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M92.90773,25.959c0-2.84912,1.67773-4.63916,4.29395-4.63916,2.625,0,4.29492,1.79,4.29492,4.63916,0,2.85645-1.66113,4.63867-4.29492,4.63867C94.56886,30.59766,92.90773,28.81543,92.90773,25.959Zm6.69531,0c0-1.95459-.89551-3.10791-2.40137-3.10791s-2.40039,1.16211-2.40039,3.10791c0,1.96191.89453,3.10645,2.40039,3.10645S99.603,27.9209,99.603,25.959Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M103.02882,21.43115h1.77246v1.541h.043a2.1594,2.1594,0,0,1,2.17773-1.63574,2.86616,2.86616,0,0,1,.63672.06934V23.144a2.59794,2.59794,0,0,0-.835-.1123,1.8728,1.8728,0,0,0-1.93652,2.0835v5.37012h-1.8584Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M116.22608,27.82617c-.25,1.64355-1.85059,2.77148-3.89844,2.77148-2.63379,0-4.26855-1.76465-4.26855-4.5957,0-2.84033,1.64355-4.68213,4.19043-4.68213,2.50488,0,4.08008,1.7207,4.08008,4.46631v.63672h-6.39453v.1123a2.358,2.358,0,0,0,2.43555,2.56445,2.04834,2.04834,0,0,0,2.09082-1.27344ZM109.94386,25.124h4.52637a2.17744,2.17744,0,0,0-2.2207-2.29834A2.29214,2.29214,0,0,0,109.94386,25.124Z"
            style={{ fill: "#fff" }}
          />
        </g>
      </g>
    </svg>
  );

  export const linkedInWhiteIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="80" viewBox="0,0,300,150"
    style={{fill:"#FFFFFF"}}>
    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(10.66667,10.66667)"><path d="M19,3h-14c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2zM9,17h-2.523v-7h2.523zM7.694,8.717c-0.771,0 -1.286,-0.514 -1.286,-1.2c0,-0.686 0.514,-1.2 1.371,-1.2c0.771,0 1.286,0.514 1.286,1.2c0,0.686 -0.514,1.2 -1.371,1.2zM18,17h-2.442v-3.826c0,-1.058 -0.651,-1.302 -0.895,-1.302c-0.244,0 -1.058,0.163 -1.058,1.302c0,0.163 0,3.826 0,3.826h-2.523v-7h2.523v0.977c0.325,-0.57 0.976,-0.977 2.197,-0.977c1.221,0 2.198,0.977 2.198,3.174z"></path></g></g>
    </svg>
  );
  
  export const InstaWhiteIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 576" style={{fill:'none',strokeMiterlimit:10,strokeWidth:'20px',height:'100px'}}><defs>.cls-1{fill="#fff"}</defs><title>Artboard 2</title><path className="cls-1" d="M235.49,398.54H178.33L178,227.41h57.16ZM205.48,205h-.36c-18.66,0-30.72-12.85-30.72-28.9,0-16.4,12.44-28.88,31.43-28.88s30.72,12.48,31.09,28.88c0,16-12.07,28.9-31.44,28.9M433.66,398.55H376v-93c0-22.47-6-37.79-26.07-37.79-15.34,0-23.65,10.34-27.67,20.32-1.47,3.58-1.84,8.56-1.84,13.54v96.92H262.47l-.33-171.14h57.95l.32,24.12c7.39-11.39,19.73-27.63,49.27-27.63,36.56,0,64,23.9,64,75.25Z"/><path className="cls-2" d="M454.67,564.74H121.32A110.07,110.07,0,0,1,11.26,454.67V121.33A110.07,110.07,0,0,1,121.32,11.26H454.67A110.07,110.07,0,0,1,564.74,121.33V454.67A110.07,110.07,0,0,1,454.67,564.74Z"/></svg>
  );
  
  export const FaceBookWhiteIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 576" style={{fill:'none',stroke:'#fff',strokeMiterlimit:10,strokeWidth:'20px',height:'50px'}}><defs> .cls-1{fill="#fff"}  </defs><title>Artboard 1</title><path className="cls-1" d="M356.9,122.52v56.31c-10.72,0-21,0-31.31,0-16.76,0-24,7.19-24.06,23.72,0,8.2,0,16.41-.11,24.6-.05,4.24.87,6.19,6.11,6,14.22-.62,28.48-.17,42.72-.48,5.27-.11,7.7,1.29,6.61,7-2.69,14.16-5.37,28.33-7.65,42.56-.81,5.16-3.16,6.78-8.16,6.68-12.79-.24-25.59-.07-39.61-.07V303q0,70.11.07,140.19c0,10.6-.5,11-11.07,11-15.31-.06-30.64-.57-45.91.13-8.31.38-9.95-2.62-9.9-10.23.29-48.66,0-97.33.32-146,.06-7.67-2.17-10.18-9.75-9.45-6.77.67-13.66,0-20.49.18-4.74.17-7.23-1.52-7.22-6.48.06-14.21,0-28.44,0-42.66,0-4.77,1.75-7.39,7.53-6.81,7.51.77,15.16.17,22.72.55,5.44.29,7-2,7.09-7.22.23-15.52-.49-31.25,1.74-46.52,4.7-32.15,21.29-49.41,53.42-53.89,21.73-3,44-2.28,66.86-3.27"/><path className="cls-2" d="M454.19,564.74H121.36A109.89,109.89,0,0,1,11.47,454.84V122A109.89,109.89,0,0,1,121.36,12.13H454.19A109.89,109.89,0,0,1,564.08,122V454.84A109.89,109.89,0,0,1,454.19,564.74Z"/></svg>
  );

  export const UserOrangeIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 19C17 17.6044 17 16.9067 16.8278 16.3389C16.44 15.0605 15.4395 14.06 14.1611 13.6722C13.5933 13.5 12.8956 13.5 11.5 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z" stroke="#ee6931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
  
  export const MailOrangeIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 4L9.16492 9.71544C9.82609 10.1783 10.1567 10.4097 10.5163 10.4993C10.8339 10.5785 11.1661 10.5785 11.4837 10.4993C11.8433 10.4097 12.1739 10.1783 12.8351 9.71544L21 4M5.8 17H16.2C17.8802 17 18.7202 17 19.362 16.673C19.9265 16.3854 20.3854 15.9265 20.673 15.362C21 14.7202 21 13.8802 21 12.2V5.8C21 4.11984 21 3.27976 20.673 2.63803C20.3854 2.07354 19.9265 1.6146 19.362 1.32698C18.7202 1 17.8802 1 16.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V12.2C1 13.8802 1 14.7202 1.32698 15.362C1.6146 15.9265 2.07354 16.3854 2.63803 16.673C3.27976 17 4.11984 17 5.8 17Z" stroke="#ee6931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
    
  );
  
  export const LanguageOrangeIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 1C13.5013 3.73835 14.9228 7.29203 15 11C14.9228 14.708 13.5013 18.2616 11 21M11 1C8.49872 3.73835 7.07725 7.29203 7 11C7.07725 14.708 8.49872 18.2616 11 21M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21M11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21M1.50002 8H20.5M1.5 14H20.5" stroke="#ee6931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  
    
  );
  
  export const SearchOrangeIcon = ({
    height,
    width,
    stroke_width,
    fill,
  }: IconProps) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" fill="#ee6931"/>
  </svg>
  
  
    
  );
  