import { Button, Grid } from "@mui/material";
import { FrankIcon } from "assets/images/FrankIcons";
import axios from "axios";
import React, { useReducer } from "react";
import { BASE_URL } from "services/HttpProvider";
import { spacing } from "utils/constants";

interface Props {
  classes?: any;
}

interface State {
  images: File[];
}

export default function URLS(props: any) {
  const { classes } = props;
  const [state, setState] = useReducer(
    (state: State, newState: any) => ({ ...state, ...newState }),
    { images: [], uploadImagesURLS: [], isDownloadBtnVisible: false }
  );

  const loadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files) {
        const imagesArray = Array.from(event.target.files);
        setState({ images: imagesArray });
        const cont = document.querySelector(".cont");
        if (cont) {
          cont.innerHTML = ""; // Clear previous images
          imagesArray.forEach((file) => {
            const image = document.createElement("img");
            image.src = URL.createObjectURL(file);
            image.width = 200;
            cont.appendChild(image);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleURLS = async () => {
    if (state?.images?.length <= 0) {
      alert("Upload Images first");
      return;
    }

    const formData = new FormData();
    const uploadedImagesUrls: string[] = [];

    state.images.forEach((image: any) => {
      formData.append("files", image);
    });

    try {
      const uploadResponse = await axios({
        baseURL: BASE_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "post",

        url: "upload/upload-multiple",
        data: formData,
      });

      uploadResponse.data.data.forEach((img: { uri: string }) => {
        uploadedImagesUrls.push(img.uri);
      });

      console.log("Uploaded Image URLs:", uploadedImagesUrls);
      setState({
        uploadImagesURLS: uploadedImagesUrls,
        images: [],
        isDownloadBtnVisible: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadUrls = () => {
    const urls = state?.uploadImagesURLS;
    const formattedUrls = urls
      ?.map((url: any, index: any) => `${index + 1}. ${url}`)
      .join("\n");

    const element = document.createElement("a");
    const file = new Blob([formattedUrls], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    window.location.reload();
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ee6931",
        }}
      >
        <FrankIcon fill={"#ee6931"} height={80} />
      </Grid>

      <Grid
        container
        style={{ padding: "100px" }}
        className={classes?.displayFlex}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          className={classes?.displayFlex}
          style={{ flexDirection: "column" }}
        >
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontSize: "42px",
              color: "#3026f1",
              fontWeight: "700",
              marginBottom: "20px",
            }}
          >
            Upload Images
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes?.displayFlex}
          style={{ flexDirection: "column" }}
        >
          <input
            multiple
            type="file"
            accept="image/*"
            name="image"
            id="file"
            onChange={loadFile}
            style={{
              border: "1px solid #ee6931",
              color: "#fff",
              background: "#ee6931",
              borderRadius: "16px",
              padding: "10px",
              display: "none",
            }}
          />
          <label
            htmlFor="file"
            style={{
              border: "1px solid #ee6931",
              color: "#fff",
              background: "#ee6931",
              borderRadius: "16px",
              padding: "10px",
              display: "inline-block",
              cursor: "pointer",
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
            }}
          >
            Choose Files
          </label>
          <div
            className="cont"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              columnGap: 20,
              rowGap: 39,
              marginTop: "20px",
            }}
          ></div>
        </Grid>

        <Grid item xs={12} className={classes?.displayFlex}>
          <button
            style={{
              border: "1px solid #ee6931",
              color: "#fff",
              background: "#ee6931",
              borderRadius: "16px",
              cursor: "pointer",
              padding: "15px",
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
            }}
            onClick={handleURLS}
          >
            Upload to Cloud
          </button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        className={classes?.displayFlex}
        style={{ maxHeight: "500px" }}
      >
        {state?.isDownloadBtnVisible ? (
          <Grid
            xs={8}
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              style={{
                marginTop: "20px",
                backgroundColor: "#ee6931",
                color: "#fff",
              }}
              onClick={handleDownloadUrls}
            >
              Download URLs
            </Button>
          </Grid>
        ) : null}

        {state?.uploadImagesURLS?.map((i: any, index: any) => {
          return (
            <Grid xs={8} item>
              <div
                style={{
                  display: "flex",
                  rowGap: 20,
                  columnGap: 20,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      fontSize: "18px",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: "#ee6931",
                      borderRadius: "100px",
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    {index + 1}{" "}
                  </span>{" "}
                  <img src={i} alt="" height={100} width={100} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  <a target="_blank" href={i}>
                    {" "}
                    URL:{i}{" "}
                  </a>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
