import * as React from "react";
import { FrankIcon } from "assets/images/FrankIcons";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { spacing } from "utils/constants";
import CustomButton from "ui-components/CustomButton";
import Toast from "ui-components/Toast/Toast";
import apiServices from "services/RequestHandler";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

function AccountDelete(props: any) {
  const [otp, setOtp] = React.useState("");
  const { classes } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isEmailDisable: false,
      showOTP: false,
      showSubmitButton: true,
    }
  );
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onInputChange = (e: any) => {
    try {
      console.log(e, "e");

      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const onCheckboxChange = (e: any) => {
    try {
      const { name, checked } = e.target;

      setState({
        [name]: checked,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const submitEmail = async () => {
    try {
      if (!state?.email) {
        Toast.Error("email cannot be empty");
        return;
      }
      if (!regEmail.test(state?.email)) {
        Toast.Error("Please enter valid email");
        return;
      }
      let obj = {
        email: state?.email,
      };
      let resp = await apiServices?.postFromApi("c2c/validate-delete", obj, "");
      setState({
        resp_otp: resp?.result,
      });
      if (resp?.status === 200 || resp?.status === 201) {
        alert(`your security code is :${resp?.result}`);
        setState({
          isEmailDisable: true,
          showSubmitButton: false,
          showOTP: true,
        });
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              resp?.message ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
      }
      console.log("resp", JSON.parse(resp));
      alert("response OTP");
    } catch (error) {}
  };
  const submitOtp = async () => {
    try {
      if (!otp) {
        Toast.Error("OTP cannot be empty"); // Ensure correct usage of Toast library
        return;
      }
      if (otp.length < 6) {
        Toast.Error("OTP length cannot be less than 6"); // Ensure correct usage of Toast library
        return;
      }

      let obj = {
        email: state?.email,
        deleted_otp: Number(otp),
      };

      let resp = await apiServices?.postFromApi(
        "c2c/verify-otp-and-delete",
        obj,
        ""
      );

      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess("Account Deleted Successfully"); // Ensure correct usage of Toast library
        navigate("/");
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              resp?.isNumber ??
              "Something went wrong. Please wait a short while and try again. If the problem persists, contact the support team."
          )
        );
      }
    } catch (error) {
      Toast.Error("An unexpected error occurred. Please try again.");
      console.error(error);
    }
  };
  const handleChange = (otp: string) => {
    if (otp.length > 6) {
      otp = otp.slice(0, 6);
    }
    setOtp(otp);
  };

  return (
    <div style={{ width: "100%" }}>
  
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ee6931",
        }}
      >
        <FrankIcon fill={"#ee6931"} height={80} />
      </Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        style={{
          padding: "100px",
        }}
        className={classes?.displayFlex}
      >
        <Grid xs={12} md={12} item className={classes?.displayFlex}>
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "42px",
              fontWeight: "700",
              color: "#ee6931",
            }}
          >
            Delete my account
          </p>
        </Grid>

        <Grid xs={12} item className={classes?.displayFlex}>
          <p style={{ fontFamily: "Raleway-Italic", fontSize: "19px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </Grid>

        <Grid xs={12} item className={classes?.displayFlex}>
          {/* <p style={{ textAlign: "right",display:'inline-block' }}>edit</p> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            
            {state?.isEmailDisable ? <div>
              <p
                style={{
                  textAlign: "right",
                  fontFamily: "Raleway-Variable",
                  color: "#ee6931",
                  cursor:'pointer'
                }}
                onClick={()=>{
                    setState({
                        isEmailDisable:false,
                        showOTP:false,
                        showSubmitButton:true,
                        is_accepted_one:false,
                        is_accepted_two:false,
                        is_accepted_three:false

                    })
                    setOtp("")
                }}
              >

                Edit
              </p>
            </div> : null}

            <div>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: "380px" }}
                onChange={onInputChange}
                value={state?.email}
                name="email"
                disabled={state?.isEmailDisable}
              />
            </div>
          </div>
        </Grid>

        {state?.showOTP ? (
          <>
            <Grid
              xs={12}
              item
              className={classes?.displayFlex}
              style={{ marginTop: "20px" }}
            >
              <div
                style={{
                  border: "1px solid lightgrey",
                  padding: "2px",
                  borderRadius: "6px",
                }}
              >
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  renderSeparator={
                    <span style={{ color: "lightgrey", fontWeight: "700" }}>
                      -
                    </span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        border: "none",
                        width: "55px",
                        height: "42px",
                        borderRadius: "8px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        background: "#f4f4f4",
                        color: "#ee6931",
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid xs={12} item className={classes?.displayFlex}>
              <FormGroup>
                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      size="small"
                      onChange={(event) => onCheckboxChange(event)}
                      name="is_accepted_one"
                      checked={state?.is_accepted_one ? true : false}
                    />
                  }
                  label="You won't be able to reactivate your account"
                />

                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      onChange={(event) => onCheckboxChange(event)}
                      name="is_accepted_two"
                      checked={state?.is_accepted_two ? true : false}
                      style={{ fontSize: "12px" }}
                      size="small"
                    />
                  }
                  label="You'll no longer be able to use Frank"
                />
                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      name="is_accepted_three"
                      onChange={(event) => onCheckboxChange(event)}
                      checked={state?.is_accepted_three ? true : false}
                      style={{ fontSize: "12px" }}
                      size="small"
                    />
                  }
                  label="Your account will be inactive for the next 30 days. then it will delete permanantly"
                />
              </FormGroup>
            </Grid>
            <Grid xs={12} item className={classes?.displayFlex}>
              <CustomButton
                value={"Submit"}
                style={{
                  background: "#ee6931",
                  fontFamily: "Raleway-Variable",
                  FontSize: "22px",
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  width: "200px",
                }}
                disable={
                  state?.is_accepted_one &&
                  state?.is_accepted_two &&
                  state?.is_accepted_three
                    ? false
                    : true
                }
                onClick={submitOtp}
              />
            </Grid>
          </>
        ) : null}

        {state?.showSubmitButton ? (
          <>
            <Grid xs={12} item className={classes?.displayFlex}>
              <CustomButton
                value={"continue"}
                style={{
                  background: "#ee6931",
                  fontFamily: "Raleway-Variable",
                  FontSize: "22px",
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  width: "200px",
                }}
                onClick={submitEmail}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
  
    </div>
  );
}

export default AccountDelete;
