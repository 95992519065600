import PropTypes from "prop-types";
import AboutPage from "views/AboutPage";
import AccountDelete from "views/AccountDetete";
import HomePage from "views/HomePage";
import LandingPage from "views/LandingPage";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsAndCondition from "views/TermsAndCondition";
import UplaodImages from "views/UploadImages";

// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    // element: <HomePage />,
    children: [
      // {
      //   path: "",
      //   element: <HomePage />,
      // },
      {
        path: "",
        element: <LandingPage classes={classes} />,

      },
      {
        path: "/about-us",
        element: <AboutPage classes={classes} />,

      },
      {
        path: "/home",
        element: <LandingPage classes={classes} />,
      },
      {
        path: "/acount-delete",
        element: <AccountDelete classes={classes} />,
      },
      {
        path: "/upload-images",
        element: <UplaodImages classes={classes} />,
      },
     
      {
        path: "privacy-policy",
        element: <PrivacyPolicy classes={classes}  />,
      },
      {
        path: ":lang/privacy-policy",
        element: <PrivacyPolicy classes={classes}  />,
      },
      {
        path: "terms-and-condition",
        element: <TermsAndCondition classes={classes}  />,
      },
      {
        path: ":lang/terms-and-condition",
        element: <TermsAndCondition classes={classes}  />,
      },
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
