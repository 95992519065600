import { FrankIcon } from "assets/images/FrankIcons";
import React from "react";
import aboutUsBanner from "../../assets/images/FrankIcons/about-main-banner@2x.png";
import { spacing } from "utils/constants";
import { Grid } from "@mui/material";
import CustomButton from "ui-components/CustomButton";
import mobileBannerTwo from "../../assets/images/FrankIcons/about-services@2x.png";
import { ArrowForward } from "@mui/icons-material";
import aboutServicesMobile from "../../assets/images/FrankIcons/notre-ambition-mobile@2x.png";
import { useNavigate } from "react-router-dom";

export default function MobileViewAboutUs(props: any) {
  const { classes } = props;
  const navigate = useNavigate()
  return (
    <div>
<Grid container>
  <Grid xs={12} item style={{ zIndex: "-1" }}>
    <img
      src={aboutUsBanner}
      style={{ objectFit: "contain", width: "100%", height: "100%", position: "relative" }}
      alt=""
    />
    <a href="/" style={{ position: "absolute", top: "30px", left: "30px", zIndex: 1 }}>
      <div style={{ height: "100px" }} onClick={()=>{
        navigate("/")
      }}>
        <FrankIcon height={80} fill={"#fff"} />
      </div>
    </a>
    <div
      style={{
        position: "absolute",
        bottom: "65%",
        left: "8%",
        width: "350px",
        zIndex: 0, // Ensure this div is below the FrankIcon
      }}
    >
      <div>
        <p
          style={{
            fontFamily: "Raleway-Italic",
            fontWeight: "900",
            fontSize: "32px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          QUI SOMMES-NOUS ?
        </p>
        <p
          style={{
            fontFamily: "Raleway-Italic",
            fontSize: "18px",
            color: "#fff",
            letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          Frank, c'est un service de livraison alternatif
        </p>
        <p
          style={{
            fontFamily: "Raleway-Italic",
            fontSize: "18px",
            color: "#fff",
            letterSpacing: "1px",
            marginTop: "5px",
            textAlign: "center",
          }}
        >
          à destination des particuliers comme des professionnels.
        </p>
      </div>
    </div>
  </Grid>
</Grid>


      <Grid
        container
        spacing={spacing?.spacing2}
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid xs={10} item>
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "32px",
              color: "#3026f1",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            NOTRE AMBITION
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              color: "#000",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Notre objectif: mettre en relation les personnes qui ont besoin
            d'envoyer un
            <br /> colis avec celles qui ont de la place dans leur véhicule. Le
            résultat ?
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={spacing?.spacing2}>
        {/* <Grid
          xs={12}
          item
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "16px",
              background: "#f9d9b6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "80%",
              flexDirection: "column",
              padding: "20px",
              gap: "10xp",
            }}
          >
            <div
              style={{
                borderRadius: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                fontFamily: "Raleway-Variable",
                fontSize: "36px",
                fontWeight: "900",
                background: "#fff",
                color: "#3026f1",
              }}
            >
              1
            </div>
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "22px",
                fontWeight: "700",
                textAlign: "center",
                color: "#ee6931",
                marginTop: "10px",
              }}
            >
              ON RATIONALISE LE MODE DE TRANSPORT INDIVIDUEL
            </div>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "16px",
              background: "#f9d9b6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "80%",
              flexDirection: "column",
              padding: "20px",
              gap: "10xp",
            }}
          >
            <div
              style={{
                borderRadius: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                fontFamily: "Raleway-Variable",
                fontSize: "36px",
                fontWeight: "900",
                background: "#fff",
                color: "#3026f1",
              }}
            >
              2
            </div>
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "22px",
                fontWeight: "700",
                textAlign: "center",
                color: "#ee6931",
                marginTop: "10px",
              }}
            >
              ON PROPOSE UNE LIVRAISON PLUS ÉCONOMIQUE
            </div>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "16px",
              background: "#f9d9b6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "80%",
              flexDirection: "column",
              padding: "20px",
              gap: "10xp",
            }}
          >
            <div
              style={{
                borderRadius: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                fontFamily: "Raleway-Variable",
                fontSize: "36px",
                fontWeight: "900",
                background: "#fff",
                color: "#3026f1",
              }}
            >
              3
            </div>
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "22px",
                fontWeight: "700",
                textAlign: "center",
                color: "#ee6931",
                marginTop: "10px",
              }}
            >
              ON LIMITE LES ÉMISSIONS DE CARBONE
            </div>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "16px",
              background: "#f9d9b6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "80%",
              flexDirection: "column",
              padding: "20px",
              gap: "10xp",
            }}
          >
            <div
              style={{
                borderRadius: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                fontFamily: "Raleway-Variable",
                fontSize: "36px",
                fontWeight: "900",
                background: "#fff",
                color: "#3026f1",
              }}
            >
              4
            </div>
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "22px",
                fontWeight: "700",
                textAlign: "center",
                color: "#ee6931",
                marginTop: "10px",
              }}
            >
              ON CRÉE UNE COMMUNAUTÉ
            </div>
          </div>
        </Grid> */}
        <Grid xs={12} item>
          <img
            src={aboutServicesMobile}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            alt=""
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid xs={8} item>
          <div style={{ borderTop: "2px dotted #3026f1" }}></div>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "10px",
        }}
      >
        <Grid xs={10} item style={{ margin: "10px" }}>
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              color: "#000",
              textAlign: "center",
            }}
          >
            Pour charger les voitures qui roulent à vide avec tous ces colis, il
            faut un lieu de rassemblement et
            <br /> une communauté. Ce lieu : c'est Frank, avec son site internet
            et son application.
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={spacing?.spacing2}>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <p style={{ paddingTop: "30px" }}>
            <CustomButton
              value={"DÉCOUVREZ QUI NOUS SOMMES"}
              classesNames={classes?.button_width}
              style={{
                background: "#fff",
                color: "#3026f1",
                border: "1px solid #3026f1",
                fontFamily: "Raleway-Variable",
                fontWeight: "700px",
                borderRadius: "8px",
                fontSize: "16px",
                height: "54px",
              }}
            />
          </p>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        style={{ background: "#ee6931", marginTop: "20px" }}
      >
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "20px",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              color: "#fff",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            FRANK, COMMENT ÇA MARCHE ?
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            <p>
              De nos jours, envoyer un colis, c'est toute une aventure ! Et plus
              il est volumineux, plus cela revient cher..
            </p>
            <br />
            <p>
              C'est pour cette raison que Frank existe : simplifier les envois
              des particuliers et des professionnels qui veulent s'y retrouver
              financièrement.
            </p>
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Pour cela, nous avons misé sur une interface simple et transparente.
            Sur notre site ou sur l'appli, vous planifiez votre envoi, vous
            définissez le tarif que vous souhaitez et hop, votre annonce est en
            ligne. Ensuite, la livraison est assurée par un transporteur de la
            communauté. Facile, non ?
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={mobileBannerTwo}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </Grid>
      </Grid>

      <Grid container spacing={spacing?.spacing2}>
        <Grid xs={12} item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // paddingLeft: "300px",
              marginTop: "20px",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "32px",
                  color: "#3026f1",
                }}
              >
                POUR QUI ?
              </p>
            </div>

            <div style={{ marginTop: "20px" }}>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Notre but, c'est de vous simplifier la vie, ou au moins l'envoi
                de vos colis !
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={spacing?.spacing2} style={{ marginTop: "10px" }}>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "300px" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "350px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowForward
                    style={{
                      fontSize: "22px",

                      color: "#3026f1",
                      // padding: "20px",
                    }}
                  />
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "28px",
                    fontWeight: "500",
                    paddingTop: "50px",
                    color: "#3026f1",
                  }}
                >
                  Expéditeurs
                </p>
              </div>
              <div style={{ paddingLeft: "30px", paddingRight: "20px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    fontWeight: "400",
                    paddingTop: "50px",
                    color: "#000",
                  }}
                >
                  Vous envoyez vos colis partout en France et profitez de tarifs
                  à toute épreuve
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "300px" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: "350px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowForward
                    style={{
                      fontSize: "22px",

                      color: "#3026f1",
                      // padding: "20px",
                    }}
                  />
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "28px",
                    fontWeight: "500",
                    paddingTop: "50px",
                    color: "#3026f1",
                  }}
                >
                  Transporteurs
                </p>
              </div>
              <div style={{ paddingLeft: "30px", paddingRight: "20px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    fontWeight: "400",
                    paddingTop: "50px",
                    color: "#000",
                  }}
                >
                  Vous rentalilisez l'espace inoccupé de votre véhicule et votre
                  trajet en prenant en charge des colis.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "300px" }}>
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "350px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowForward
                    style={{
                      fontSize: "22px",

                      color: "#3026f1",
                      // padding: "20px",
                    }}
                  />
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "28px",
                    fontWeight: "500",
                    paddingTop: "50px",
                    color: "#3026f1",
                  }}
                >
                  E-coomerçants
                </p>
              </div>
              <div style={{ paddingLeft: "30px", paddingRight: "20px" }}>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    fontWeight: "400",
                    paddingTop: "50px",
                    color: "#000",
                  }}
                >
                  Vous faites vos envois de façon éco-responsable et économique.
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        style={{ background: "#3026f1", marginTop: "30px" }}
      >
        <Grid xs={12} item>
          <div>
            <p
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "32px",
                color: "#fff",
                textAlign: "center",
              }}
            >
              NOS VALEURS
            </p>
          </div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ borderTop: "1px solid #fff", width: "100%" }}></div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              fontWeight: "800",
              color: "#f9d9b6",
            }}
          >
            Connecter les personnes
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "16px",
              color: "#fff",
              paddingTop: "20px",
            }}
          >
            Pour nous, "mettre en relation" des personnes,
            <br /> ce n'est pas uniquement créer une plateforme
            <br /> collaborative où chacun trouve son compte.
            <br />{" "}
            <span style={{ fontWeight: "800" }}>
              {" "}
              C'est aussi rapprocher les gens et créer une
              <br /> communauté qui partage nos valeurs
              <br /> d'entraide, d'efficacité et de convivialité
            </span>{" "}
            : <br />
            les Frankies.
          </p>

          <div
            style={{
              borderTop: "1px solid #fff",
              marginTop: "50px",
            }}
          ></div>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              fontWeight: "800",
              color: "#f9d9b6",
            }}
          >
            Efficacité et simplicité
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "16px",
              color: "#fff",
              paddingTop: "20px",
            }}
          >
            Efficacité et simplicité Nous avons pensé notre
            <br /> système de la façon la plus simple possible:
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "18px",
              color: "#fff",
              fontWeight: "800",
              paddingTop: "10px",
            }}
          >
            • Mise en relation
            <br />
            • Prise en charge du colis
            <br />• Réception de la commande
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "18px",
              color: "#fff",
              paddingTop: "10px",
            }}
          >
            Vous faites des économies, mais vous y<br /> gagnez aussi en
            sérénité et en sécurité.
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ borderTop: "1px solid #fff", width: "100%" }}></div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              fontWeight: "800",
              color: "#f9d9b6",
              textAlign: "center",
            }}
          >
            Un impact positif sur
            <br /> l'écologie... et sur votre
            <br /> portefeuille
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "16px",
              fontWeight: "600",
              color: "#fff",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Saviez-vous qu'un grand nombre de <br /> voitures roulent avec une
            seule personne
            <br /> à l'intérieur ?
          </p>

          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "16px",
              color: "#fff",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            Du côté des camions de transport,
            <br /> beaucoup de trajets se font avec un coffre
            <br /> rempli partiellement, ou carrément vide ! En
            <br /> remplissant ces véhicules vides avec des
            <br /> colis à distribuer, Frank contribue à réduire
            <br /> l'empreinte carbone des envois, mais aussi
            <br /> des trajets. Et avec nos tarifs compétitifs,
            <br /> tout le monde est content!
          </p>
        </Grid>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ borderTop: "1px solid #fff", width: "100%" }}></div>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            flexDirection: "column",
            height: "130px",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "22px",
              fontWeight: "700",
              color: "#f9d9b6",
            }}
          >
            Qui sommes-nous ?
            <br />
            Nous Contacter :
          </p>
          <p
            style={{
              color: "#ee6931",
              fontFamily: "Raleway-variable",
              fontWeight: "800",
            }}
          >
            <a
              href="mailto:Hello@frankme.com"
              style={{
                textDecoration: "none",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              <span style={{ color: "#ee6931" }}> → </span>Hello@frankme.com
            </a>
          </p>{" "}


          <div style={{ fontFamily: "Raleway-Variable", fontSize: "14px",color:'#ee6931',paddingTop:'20px' }}>
             <a href="/terms-and-condition" style={{textDecoration:'none',fontFamily:'Raleway-Variable',color:'#fff'}}>  Termes et conditions</a> &nbsp; |  &nbsp;<a href="/privacy-policy" style={{textDecoration:'none',fontFamily:'Raleway-Variable',color:'#fff'}}> Politique de confidentialité</a>
            </div>
        </Grid>

    
      </Grid>
    </div>
  );
}
